import { Link } from 'react-router-dom';
import logo from  '../assets/images/logo.jpg'
function Navbarmenu() {
  return (
    <>     
       <nav className="navbar navbar-expand-lg navbar-light bg-light">
  <div className="container-fluid">
    <Link className="navbar-brand text-success" to="/"><img src={logo} alt="logo" title="logo"/></Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNav">
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link className="nav-link text-success active" aria-current="page" to="/">Home</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link text-success" to="/about">About Us</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link text-success" to="/app-product">Add Product</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link text-success" to="/cources">Cources</Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link text-success" to="/register">Signup </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link text-success" to="/contact-us">Contact Us <i className="fas fa-heart"></i></Link>
        </li>
       
      </ul>
    </div>
  </div>
</nav>
    </>
  )
 
}

export default Navbarmenu;