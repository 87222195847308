import cource1 from '../assets/images/course_1.jpg'
import bgImg from  '../assets/images/bg_1.jpg'
import {Link} from 'react-router-dom'
function Cource(){
    const allList = ['React Js','Angular','Node','Php','Javascript'];
    const Languages= ["French", "German", "English", "Spanish" , "Mandarin"];
    return (<>
        
        <div className="site-section ftco-subscribe-1 site-blocks-cover pb-4" style={{ backgroundImage: `url(${bgImg})` }}>
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-7">
              <h2 className="mb-0">Courses</h2>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing.</p>
            </div>
          </div>
        </div>
      </div> 
       <div className="custom-breadcrumns border-bottom">
      <div className="container">
        <Link to="/">Home</Link>
        <span className="mx-3 icon-keyboard_arrow_right"></span>
        <span className="current">Courses</span>
      </div>
    </div>

    <div className="site-section">
        <div className="container">
            <div className="row">
            {allList.map(Language => (
                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="course-1-item">
                        <figure className="thumnail">
                        <Link href="course-single.html"><img src={cource1} alt="Cource" className="img-fluid"/></Link>
                        <div className="price">$99.00</div>
                        <div className="category"><h3>{Language}</h3></div>  
                        </figure>
                        <div className="course-1-content pb-4">
                        <h2>How To Create Mobile Apps Using Ionic</h2>
                        <div className="rating text-center mb-3">
                            <span className="icon-star2 text-warning"></span>
                            <span className="icon-star2 text-warning"></span>
                            <span className="icon-star2 text-warning"></span>
                            <span className="icon-star2 text-warning"></span>
                            <span className="icon-star2 text-warning"></span>
                        </div>
                        <p className="desc mb-4">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Similique accusantium ipsam.</p>
                        <p><Link href="course-single.html" className="btn btn-primary rounded-0 px-4">Enroll In This Course</Link></p>
                        </div>
                    </div>
                </div>
            ))}     

            </div>
        </div>
    </div>
    </>)
}
export default Cource