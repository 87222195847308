import bgImg from  '../assets/images/bg_1.jpg'
import {Link} from  'react-router-dom'
function Contact(){
    return (<>
       <div className="site-section ftco-subscribe-1 site-blocks-cover pb-4" style={{ backgroundImage: `url(${bgImg})` }}>
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-7">
              <h2 className="mb-0">Contact Us</h2>
              <p>You can submit your query here.</p>
            </div>
          </div>
        </div>
      </div> 
      <div className="custom-breadcrumns border-bottom">
      <div className="container">
        <Link to="/">Home</Link>
        <span className="mx-3 icon-keyboard_arrow_right"></span>
        <span className="current">Contact Us</span>
      </div>
    </div>

    <div className="site-section">
        <div className="container">


            <div className="row justify-content-center">
                <div className="col-md-5">
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label>Name</label>
                            <input type="text" id="username" placeholder='Please enter name' className="form-control form-control-lg"/>
                        </div>
                        <div className="col-md-12 form-group">
                            <label >Email</label>
                            <input type="text" id="email" placeholder='Please enter email' className="form-control form-control-lg"/>
                        </div>
                        <div className="col-md-12 form-group">
                            <label >Mobile</label>
                            <input type="text" id="pword" placeholder='Please enter Mobile number' className="form-control form-control-lg"/>
                        </div>
                        <div className="col-md-12 form-group">
                            <label >Message</label>
                            <textarea placeholder='Enter message' className='form-control'></textarea>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mt-4">
                            <input type="button" value="Submit" className="btn btn-success btn-lg px-5"/>
                        </div>
                    </div>
                </div>
            </div>
            

          
        </div>
    </div>
    </>)
}
export default Contact