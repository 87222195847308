import bgImg from  '../assets/images/bg_1.jpg'
import {Link} from 'react-router-dom'
function Signup(){
    return (<>
     <div className="site-section ftco-subscribe-1 site-blocks-cover pb-4" style={{ backgroundImage: `url(${bgImg})` }}>
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-7">
              <h2 className="mb-0">Signup</h2>
              <p>New user register here.</p>
            </div>
          </div>
        </div>
      </div> 
       <div className="custom-breadcrumns border-bottom">
      <div className="container">
        <Link to="/">Home</Link>
        <span className="mx-3 icon-keyboard_arrow_right"></span>
        <span className="current">Register</span>
      </div>
    </div>

    <div className="site-section">
        <div className="container">


            <div className="row justify-content-center">
                <div className="col-md-5">
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label>Username</label>
                            <input type="text" id="username" className="form-control form-control-lg"/>
                        </div>
                        <div className="col-md-12 form-group">
                            <label >Email</label>
                            <input type="email" id="email" className="form-control form-control-lg"/>
                        </div>
                        <div className="col-md-12 form-group">
                            <label >Password</label>
                            <input type="password" id="pword" className="form-control form-control-lg"/>
                        </div>
                        <div className="col-md-12 form-group">
                            <label >Re-type Password</label>
                            <input type="password" id="pword2" className="form-control form-control-lg"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mt-4">
                            <input type="submit" value="Register" className="btn btn-success btn-lg px-5"/>
                        </div>
                         <Link to="/sign-in">Sign In</Link>
                    </div>
                    
                </div>
            </div>
           

          
        </div>
    </div>
    
    
    </>)
}
export default Signup