import bgImg from  '../assets/images/bg_1.jpg'
import course4 from '../assets/images/course_4.jpg'
import course5 from '../assets/images/course_5.jpg'
import hero_1 from '../assets/images/hero_1.jpg'
import person_3 from '../assets/images/person_3.jpg'
import person_2 from '../assets/images/person_2.jpg'
import person_1 from '../assets/images/person_1.jpg'
export function About(){
    return (<>
        <div className="site-section ftco-subscribe-1 site-blocks-cover pb-4" style={{ backgroundImage: `url(${bgImg})` }}>
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-7">
              <h2 className="mb-0">About Us</h2>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing.</p>
            </div>
          </div>
        </div>
      </div> 
    

    <div className="custom-breadcrumns border-bottom">
      <div className="container">
        <a href="#">Home</a>
        <span className="mx-3 icon-keyboard_arrow_right"></span>
        <span className="current">About Us</span>
      </div>
    </div>

    <div className="container pt-5 mb-5">
            <div className="row">
              <div className="col-lg-4">
                <h2 className="section-title-underline">
                  <span>Academics History</span>
                </h2>
              </div>
              <div className="col-lg-4">
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ut, iure dolorum! Nam veniam tempore tenetur aliquam architecto, hic alias quia quisquam, obcaecati laborum dolores. Ex libero cumque veritatis numquam placeat?</p>
              </div>
              <div className="col-lg-4">
                <p>Nam veniam tempore tenetur aliquam
                architecto, hic alias quia quisquam, obcaecati laborum dolores. Ex libero cumque veritatis numquam placeat?</p>
              </div>
            </div>
          </div> 

    <div className="site-section">
        <div className="container">
            <div className="row mb-5">
                <div className="col-lg-6 mb-lg-0 mb-4">
                    <img src={course4} alt="course4" className="img-fluid"/> 
                </div>
                <div className="col-lg-5 ml-auto align-self-center">
                    <h2 className="section-title-underline mb-5">
                        <span>Why Academics Works</span>
                    </h2>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. At itaque dolore libero corrupti! Itaque, delectus?</p>
                    <p>Modi sit dolor repellat esse! Sed necessitatibus itaque libero odit placeat nesciunt, voluptatum totam facere.</p>
                </div>
            </div>

            <div className="row">
                    <div className="col-lg-6 order-1 order-lg-2 mb-4 mb-lg-0">
                        <img src={course5} alt="course5" className="img-fluid"/> 
                    </div>
                    <div className="col-lg-5 mr-auto align-self-center order-2 order-lg-1">
                        <h2 className="section-title-underline mb-5">
                            <span>Personalized Learning</span>
                        </h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. At itaque dolore libero corrupti! Itaque, delectus?</p>
                        <p>Modi sit dolor repellat esse! Sed necessitatibus itaque libero odit placeat nesciunt, voluptatum totam facere.</p>
                    </div>
                </div>
        </div>
    </div>

    <div className="section-bg style-1" style={{ backgroundImage: `url(${hero_1})` }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-5 mb-lg-0">
              <span className="icon flaticon-mortarboard"></span>
              <h3>Our Philosphy</h3>
              <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis recusandae, iure repellat quis delectus ea? Dolore, amet reprehenderit.</p>
            </div>
            <div className="col-lg-4 col-md-6 mb-5 mb-lg-0">
              <span className="icon flaticon-school-material"></span>
              <h3>Academics Principle</h3>
              <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis recusandae, iure repellat quis delectus ea?
                Dolore, amet reprehenderit.</p>
            </div>
            <div className="col-lg-4 col-md-6 mb-5 mb-lg-0">
              <span className="icon flaticon-library"></span>
              <h3>Key of Success</h3>
              <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis recusandae, iure repellat quis delectus ea?
                Dolore, amet reprehenderit.</p>
            </div>
          </div>
        </div>
      </div>
      

    <div className="site-section">
      <div className="container">
        <div className="row mb-5 justify-content-center text-center">
          <div className="col-lg-4 mb-5">
            <h2 className="section-title-underline mb-5">
              <span>Our Teachers</span>
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-5 mb-lg-5">

            <div className="feature-1 border person text-center">
                <img src={person_1} alt="Pesron1" className="img-fluid"/>
              <div className="feature-1-content">
                <h2>Craig Daniel</h2>
                <span className="position mb-3 d-block">Math Teacher</span>    
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit morbi hendrerit elit</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-5 mb-lg-5">
            <div className="feature-1 border person text-center">
                <img src={person_2} alt="Person2" className="img-fluid"/>
              <div className="feature-1-content">
                <h2>Taylor Simpson</h2>
                <span className="position mb-3 d-block">Math Teacher</span>    
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit morbi hendrerit elit</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-5 mb-lg-5">
            <div className="feature-1 border person text-center">
                <img src={person_3} alt="Person3" className="img-fluid"/>
              <div className="feature-1-content">
                <h2>Jonas Tabble</h2>
                <span className="position mb-3 d-block">Math Teacher</span>    
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit morbi hendrerit elit</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 mb-5 mb-lg-5">

            <div className="feature-1 border person text-center">
                <img src={person_3} alt="Person4" className="img-fluid"/>
              <div className="feature-1-content">
                <h2>Craig Daniel</h2>
                <span className="position mb-3 d-block">Math Teacher</span>    
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit morbi hendrerit elit</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-5 mb-lg-5">
            <div className="feature-1 border person text-center">
                <img src={person_2} alt="Person2" className="img-fluid"/>
              <div className="feature-1-content">
                <h2>Taylor Simpson</h2>
                <span className="position mb-3 d-block">Math Teacher</span>    
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit morbi hendrerit elit</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-5 mb-lg-5">
            <div className="feature-1 border person text-center">
                <img src={person_3} alt="Person3" className="img-fluid"/>
              <div className="feature-1-content">
                <h2>Jonas Tabble</h2>
                <span className="position mb-3 d-block">Math Teacher</span>    
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit morbi hendrerit elit</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    
    


    

    
    <div className="site-section ftco-subscribe-1" style={{ backgroundImage: `url(${bgImg})` }}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7">
            <h2>Subscribe to us!</h2>
            <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia,</p>
          </div>
          <div className="col-lg-5">
            <form action="" className="d-flex">
              <input type="text" className="rounded form-control mr-2 py-3" placeholder="Enter your email"/>
              <button className="btn btn-primary rounded py-3 px-4" type="submit">Send</button>
            </form>
          </div>
        </div>
      </div>
    </div> 

    </>)
}