import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/style.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Addproduct from './components/Addproduct';
import Navbarmenu from './components/Navbarmenu';
import Signup from './components/Signup';
import { About } from './components/About';
import Footer from './components/Footer';
import Cource from './components/Cources';
import Contact from './components/Contact';
import Login from './components/Login';
import Notfound from './components/Notfound';
import 'font-awesome/css/font-awesome.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <Navbarmenu/>
  <Routes>
    <Route path="/" element={<App/>} />
    <Route path="/add-product" element={<Addproduct/>} />
    <Route path="/register" element={<Signup/>} />
    <Route path="/about" element={<About/>} />
    <Route path="/cources" element={<Cource/>} />
    <Route path="/contact-us" element={<Contact/>} />
    <Route path="/sign-in" element={<Login/>}/>
    <Route path="/*" element={<Notfound/>}/>
  </Routes>
  <Footer/>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
