import bgImg from  '../assets/images/bg_1.jpg'
import {Link} from 'react-router-dom'
function Notfound(){
    return (<>
    <div className="site-section ftco-subscribe-1 site-blocks-cover pb-4" style={{ backgroundImage: `url(${bgImg})` }}>
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-7">
              <h2 className="mb-0">404</h2>
              <p>Not found</p>
            </div>
          </div>
        </div>
      </div> 
      <div className="custom-breadcrumns border-bottom">
      <div className="container">
        <Link to="/">Home</Link>
        <span className="mx-3 icon-keyboard_arrow_right"></span>
        <span className="current">404</span>
      </div>
    </div>
    <div>
        404 Page
    </div>
    </>)
}
export default Notfound