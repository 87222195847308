import bg1 from '../assets/images/bg_1.jpg'
function Subcribe(){
    return (<>
          <div className="site-section ftco-subscribe-1" style={{backgroundImage:`url(${bg1})`}}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7">
            <h2>Subscribe to us!</h2>
            <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia,</p>
          </div>
          <div className="col-lg-5">
            <form action="" className="d-flex">
              <input type="text" className="rounded form-control mr-2 py-3" placeholder="Enter your email"/>
              <button className="btn btn-success subscribe rounded py-3 px-4" type="submit">Send</button>
            </form>
          </div>
        </div>
      </div>
    </div> 
    </>)
}
export default Subcribe