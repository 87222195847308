import Slider from './components/Slider';
import Academics from './components/Academeic';
import AboutUniversity from './components/AboutUniversity';
import Subcribe from './components/Subscribe';
import News from './components/News';
function App() {
  return (<>
  <Slider/>
  <Academics/>
  <AboutUniversity/>
  <News/>
  <Subcribe/>
  </>)
}

export default App;
