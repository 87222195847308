import blog_large_1 from '../assets/images/blog_large_1.jpg'
import blog_2 from '../assets/images/blog_2.jpg'
import blog_1 from '../assets/images/blog_1.jpg'
import course_5 from  '../assets/images/course_5.jpg'
function News(){
    return (<>
     <div className="news-updates">
      <div className="container">
         
        <div className="row">
          <div className="col-lg-9">
             <div className="section-heading">
              <h2 className="text-black">News &amp; Updates</h2>
              <a href="#">Read All News</a>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="post-entry-big">
                  <a href="news-single.html" className="img-link"><img src={blog_large_1} alt="blog1" className="img-fluid"/></a>
                  <div className="post-content">
                    <div className="post-meta"> 
                      <a href="#">June 6, 2019</a>
                      <span className="mx-1">/</span>
                      <a href="#">Admission</a>, <a href="#">Updates</a>
                    </div>
                    <h3 className="post-heading"><a href="news-single.html">Campus Camping and Learning Session</a></h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="post-entry-big horizontal d-flex mb-4">
                  <a href="news-single.html" className="img-link mr-4"><img src={blog_1} alt="Imaged" className="img-fluid"/></a>
                  <div className="post-content">
                    <div className="post-meta">
                      <a href="#">June 6, 2019</a>
                      <span className="mx-1">/</span>
                      <a href="#">Admission</a>, <a href="#">Updates</a>
                    </div>
                    <h3 className="post-heading"><a href="news-single.html">Campus Camping and Learning Session</a></h3>
                  </div>
                </div>

                <div className="post-entry-big horizontal d-flex mb-4">
                  <a href="news-single.html" className="img-link mr-4">
                    <img src={blog_2} alt="Image" className="img-fluid"/></a>
                  <div className="post-content">
                    <div className="post-meta">
                      <a href="#">June 6, 2019</a>
                      <span className="mx-1">/</span>
                      <a href="#">Admission</a>, <a href="#">Updates</a>
                    </div>
                    <h3 className="post-heading"><a href="news-single.html">Campus Camping and Learning Session</a></h3>
                  </div>
                </div>

                <div className="post-entry-big horizontal d-flex mb-4">
                  <a href="news-single.html" className="img-link mr-4">
                    <img src={blog_1} alt="Imagedd" className="img-fluid"/></a>
                  <div className="post-content">
                    <div className="post-meta">
                      <a href="#">June 6, 2019</a>
                      <span className="mx-1">/</span>
                      <a href="#">Admission</a>, <a href="#">Updates</a>
                    </div>
                    <h3 className="post-heading"><a href="news-single.html">Campus Camping and Learning Session</a></h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="section-heading">
              <h2 className="text-black">Campus Videos</h2>
              <a href="#">View All Videos</a>
            </div>
            <a href="https://vimeo.com/45830194" className="video-1 mb-4" data-fancybox="" data-ratio="2">
              <span className="play">
                <span className="icon-play"></span>
              </span>
              <img src={course_5} alt="Imageh" className="img-fluid"/>
            </a>
            <a href="https://vimeo.com/45830194" className="video-1 mb-4" data-fancybox="" data-ratio="2">
                <span className="play">
                  <span className="icon-play"></span>
                </span>
                <img src={course_5} alt="Course5" className="img-fluid"/>
              </a>
          </div>
        </div>
      </div>
    </div>

    </>)
}
export default News