import logo from '../assets/images/logo.png';
import {Link} from 'react-router-dom';
function Footer(){
    return <>
     <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <p className="mb-4"><img src={logo} alt="logo" title="Logo" className="img-fluid"/></p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae nemo minima qui dolor, iusto iure.</p>  
            <p><Link to="#">Learn More</Link></p>
          </div>
          <div className="col-lg-3">
            <h3 className="footer-heading"><span>Our Campus</span></h3>
            <ul className="list-unstyled">
                <li><Link to="/about">About Us</Link></li>
                <li><Link to="#">News</Link></li>
                <li><Link to="#">Our Interns</Link></li>
                <li><Link to="#">Our Leadership</Link></li>
                <li><Link to="#">Careers</Link></li>
                <li><Link to="#">Human Resources</Link></li>
            </ul>
          </div>
          <div className="col-lg-3">
              <h3 className="footer-heading"><span>Our Courses</span></h3>
              <ul className="list-unstyled">
                  <li><Link to="#">Math</Link></li>
                  <li><Link to="#">Science &amp; Engineering</Link></li>
                  <li><Link to="#">Arts &amp; Humanities</Link></li>
                  <li><Link to="#">Economics &amp; Finance</Link></li>
                  <li><Link to="#">Business Administration</Link></li>
                  <li><Link to="#">Computer Science</Link></li>
              </ul>
          </div>
          <div className="col-lg-3">
              <h3 className="footer-heading"><span>Contact</span></h3>
              <ul className="list-unstyled">
                  <li><Link to="#">Help Center</Link></li>
                  <li><Link to="#">Support Community</Link></li>
                  <li><Link to="#">Press</Link></li>
                  <li><Link to="#">Share Your Story</Link></li>
                  <li><Link to="#">Our Supporters</Link></li>
              </ul>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="copyright">
                <p>
                   
                    Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved 
                  
                    </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
}

export default Footer